.country-and-language-popup {
  .country-dropdown {
    width: 120px;
    margin: 0 auto;
  }
  .languages-dropdown {
    width: 100px;
    margin: 0 auto;
    margin-left: 0px;
  }
  .disable {
    pointer-events: none;
    background-color: grey;
  }
  .enable {
    pointer-events: auto;
  }
  #country-form label {
    line-height: 2;
  }
  #language-form label {
    line-height: 2;
  }
}
