@import '../../styles/main.scss';
.footer {
  margin: 0 auto!important;
  color: $light-grey-2;
  background-color: $black;
  .footer-link {
    padding-bottom: 5px!important;
    padding-top: 5px!important;
  }
  .external-link {
    text-decoration: underline;
    font-size: 12px;
  }
  .external-link-icon {
    margin-left: 5px;
    color: $light-grey-2;
  }
  .copy-right {
    border-top: 1px solid $grey-200;
    margin: 10px 20px;
    /*padding-top: 10px;*/
    color: $light-grey-0;
    font-size: 9px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .footer-tab {
    cursor: pointer;
  }
  .footer-buttons {
    color: $light-grey-2;
    text-transform: initial;
    font-size: 12px;
    font-weight: initial;
  }
  a {
    color: $white;
  }
}
