@import 'src/styles/main.scss';
.terms-and-conditions {
  .decline-button {
    margin-right: 10px!important;
    background-color: $grey-100;
    color: white;
  }
  .decline-button:hover {
    background-color: #9d9fa6;
    color: white;
  }
  .bottom-text {
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
  }
}
.terms-heading  h4 {
  margin: 0px;
}
.terms-title > span {
  width: 600px;
}
.language-selector {
  margin-right: 25px!important;
}
