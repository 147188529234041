@import '../../styles/main.scss';
.app-nav {
  .tabs {
    padding: 10px 0px 10px 0px;
  }
  .mansart-logo {
    margin: 10px 0px 0px 40px;
    color: $white;
    font-weight: 700;
    font-size: 18px;
  }
  .nav-container {
    background-color: $black;
  }
  div[index='3'] {
    display: none;
  }
  #main-header-tabs .label-wrapper {
    font-size: 14px;
  }
}
.footer-container{
  margin-top: auto;
}
.app-content {
  margin-bottom: 0px;
}
