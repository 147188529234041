body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: 'Amplitude-Book';
  src: local('Amplitude-Book'), url(./fonts/Amplitude-Book.otf) format('tof');
}
@font-face {
  font-family: 'Amplitude-Light';
  src: local('Amplitude-Light'), url(./fonts/Amplitude-Light.otf) format('tof');
}
@font-face {
  font-family: 'Amplitude-Medium';
  src: local('Amplitude-Medium'), url(./fonts/Amplitude-Medium.otf) format('tof');
}
@font-face {
  font-family: 'AmplitudeTF-Bold';
  src: local('AmplitudeTF-Bold'), url(./fonts/AmplitudeTF-Bold.otf) format('tof');
}
@font-face {
  font-family: 'AmplitudeWide-Regular';
  src: local('AmplitudeWide-Regular'), url(./fonts/AmplitudeWide-Regular.otf) format('tof');
}
