@import 'variables/colors';
.app-container {
  min-height: 100vh;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  background-color: $grey-15;
}
.grid-header-string {
  text-align: right;
}
.number-cell {
  text-align: right;
}
.grid-header {
  background-color: $grey-15!important;
}
.ag-theme-material .ag-header-cell:hover {
  background: $grey-15!important;
}
.ag-theme-material .ag-layout-auto-height .ag-center-cols-clipper, .ag-theme-material .ag-layout-auto-height .ag-center-cols-container, .ag-theme-material .ag-layout-print .ag-center-cols-clipper, .ag-theme-material .ag-layout-print .ag-center-cols-container {
  min-height: 45px !important;
}
.no-data {
  text-align: center;
}
.error-message-container {
  margin: 0 auto;
  margin-top: 15px;
}
.heading {
  font-family: AmplitudeTF, Amplitude-Book;
}
.ag-theme-material .ag-checkbox-input-wrapper {
  border-color: #85888f !important;
}
.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  background-color: #2871aa !important;
}
.disable-check-box {
  pointer-events: none;
}
.disable-check-box .ag-selection-checkbox {
  opacity: .5;
}
