@import 'src/styles/main.scss';
.regulatory-document-card {
  .external-link-icon {
    margin-right: 10px;
  }
  .links {
    margin-top: 5px;
  }
  .card-background {
    background-color: $turquoise-light-10;
  }
}
.card-style {
  background: transparent!important;
  box-shadow: none!important;
  border: none!important;
}
