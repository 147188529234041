@import 'src/styles/main.scss';
.regulatory-and-disclosures {
  .full-width {
    width: 100%;
    height: 160px;
    overflow: hidden;
  }
  .regulatory-image {
    background-color: #0f181f;
    background-image: url("../../images/Regulatory_Disclousures_Header.jpg");
    background-repeat: no-repeat;
    width: 100%;
    background-position: bottom;
    color: $white;
    background-position-x: right;
    background-size: contain;
    line-height: 160px;
  }
  .heading {
    vertical-align: middle;
    display: inline-block;
    line-height: normal;
    font-size: 40px;
    margin-left: 40px;
  }
  .regulatory-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:0px ;
    grid-auto-rows: minmax(auto, auto);
    padding: 5px;
  }
  .regulatory-grid-item {
    background-color: transparent;
    border: 1px solid #C5C9D0;
    height: auto;
    text-align: left;
    line-height: 1.3;
    gap:5px;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    margin: 10px 10px;
  }
  .regulatory-grid-item a {
    text-decoration: none;
    font-weight: bold;
    font-size: 13px;
  }
  .regulatory-cards-container {
    margin: 0 auto;
    margin-top: 27px;
    max-width: 1500px;
  }
  .regulatory-trade-cards-container {
    margin: 0 auto;
    margin-top: 17px;
    max-width: 1500px;
  }
  .regulatory-links-cards-container {
    margin: 0 auto;
    margin-top: 17px;
    max-width: 1500px;
    margin-bottom: 22px;
  }
  .section-headings {
    margin-left: 30px;
  }
  .section-headings > h3 {
    margin: 0px;
  }
  .pre-loader {
    width: 25px;
    margin: 0 auto;
    margin-top: 40px;
  }
  @media only screen and (max-width: 1580px){
    .regulatory-cards-container {
      max-width: calc(100vw - 44px);
    }
    .regulatory-trade-cards-container {
      max-width: calc(100vw - 44px);
    }
    .regulatory-links-cards-container {
      max-width: calc(100vw - 44px);
    }
  }
}
